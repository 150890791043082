@font-face {
  font-family: 'HanaMinPlus';
  src: local('HanaMinPlus'), url(/assets/fonts/HanaMinPlus.ttf) format('ttf');
}

@font-face {
  font-family: 'Castoro';
  src: local('Castoro'), url(/assets/fonts/Castoro-Regular.ttf) format('ttf');
}

body {
  overflow: hidden;
  overflow-y: scroll;
  font-family: HanaMinPlus;
}

.ui.menu.nav-menu {
  border-radius: 0;
  margin-bottom: 0;
}

.ui.container {
  margin-top: 20px;

  &.nav-container {
    margin-top: 0;
  }
}

.tab-wrapped {
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
}

.ui.menu .item > img:not(.ui) {
  display: inline-block;
  vertical-align: middle;
  margin: -0.3em 0;
  width: 120px;
}

.ui.inverted.menu .item, .ui.inverted.menu .item>a:not(.ui) {
  color :#000000;
}

.ui.menu.nav-menu {
  background-color: white;
}

.ui.grid > .row > .column {
  margin: 15px auto;
}

.home-model-card {
  font-family: 'Castoro';
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  text-align: center;
  img {
    object-fit: contain;
    max-width: 100%;
  }
}

canvas {
  width: 100%;
  height: 100%;
  height: 800px;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;

  @media (max-width: 768px) {
    max-height: 75vh;
  }

  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}

.home-card-title {
  font-size: 40px !important;
  font-weight: bold;
}
.home-card-sub-title {
  font-size: 30px !important;  
  margin-bottom: 25px;
  
  font-weight: bold;
}
.sigupinputold {
  display: none;
}
.drag-notice {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  width: 10em;
  height: 10em;
  box-sizing: border-box;
  font-size: 1.2em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  border-radius: 50%;
  background: #eee;
  animation: popout 0.25s 3s forwards;
  position: absolute;
  top: -200px;
  left: -75px;
}

@keyframes popout {
  to {
    transform: scale(0);
  }
}

.model-loading {
  position: fixed;
  z-index: 50;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.model-loader {
  -webkit-perspective: 120px;
  -moz-perspective: 120px;
  -ms-perspective: 120px;
  perspective: 120px;
  width: 100px;
  height: 100px;
  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #eb144c;
    animation: flip 1s infinite;
  }
}

@keyframes flip {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}

.steps {
  overflow-x: scroll;
}

.cart-qqt {
  &:hover {
    cursor: inherit;
  }
}

.cart-card {
  .corner {
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}

.cart-subtotal {
  font-size: 18px !important;
  text-align: right;
}

.cart-card-img {
  max-width: 100%;
  object-fit: cover;
}

.cart-table-headers {
  font-size: 18px;
}

// .ui.bound.bottom.sticky {
// top: 40px !important;
// }

.ui.breadcrumb {
  font-size: 18px !important;
}

.checkout-order-info {
  background-color: #f8f8f8 !important;
}

.ecpay-button {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.checkout-table {
  margin: 0 auto !important;
}

.order-detail-steps {
  width: 100% !important;
}

.ui.top.attached.label {
  font-size: 15px;
}

.user-order-info {
  font-size: 18px;
}

.cart-subtotal-text {
  font-weight: 100;
  color: #888;
}

.cart-total-text {
  font-weight: bold;
  color: blue;
  font-size: 28 !important;
}

.product-modal-title {
  font-size: 32px !important;
}

.product-modal-description {
  .header {
    font-size: 32px !important;
  }
  padding-left: 50px;
  p {
    font-size: 24px;
  }
}

.model-text {
  font-family: 'Castoro';
}

.circle-picker div {
  border: 1px solid black;
  border-radius: 50%;
}


.each-slide-effect > div {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-size: cover;
}
.btnValidate {
  margin-bottom: 10px;
  text-align: right;

}
.each-slide-effect {
  height: 500px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.hiddenClass {
  display: none;
}



.ui.menu:not(.secondary):not(.text):not(.tabular):not(.borderless)>.container>.item:not(.right):not(.borderless):first-child {

  border-left:  0px solid rgba(34,36,38,.1);
}